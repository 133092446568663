import { CreateImageDto } from '../fixsirat-admin-backend/src/images/dto/create-image.dto';

export enum Severity {
  Low = 1,
  Medium = 2,
  High = 3,
}
export enum MaterialType {
  A01 = 'A01',
  A02 = 'A02',
  A03 = 'A03',
  B01 = 'B01',
  B02 = 'B02',
  B03 = 'B03',
}

export enum IssueStatus {
  PENDING = 'Pending',
  RESOLVED = 'Resolved',
  CLOSED = 'Closed',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  RETURNED = 'Returned',
  REOPENED = 'Reopened',
  REMOVED = 'Removed',
}

export enum IssueStatusDetail {
  PENDING = 'Issue is pending for administration approval.',
  RESOLVED = 'Issue is resolved.',
  CLOSED = 'Issue is closed.',
  APPROVED = 'Issue is approved by administration.',
  REJECTED = 'Issue is rejected.',
  RETURNED = 'Issue is returned.',
  REOPENED = 'Issue is reopened.',
  REMOVED = 'Issue is removed.',
}

export interface imageType {
  dateTaken?: string;
  imageLink: string;
  issueId?: string;
  isBefore?: boolean;
  isSelectedImage?: boolean;
}

export interface issueImages {
  issueImages?: imageType[];
}
export interface issue extends issueImages {
  reporter: string;
  userName: string;
  address?: string;
  severity: Severity;
  mapURL: string;
  images?: CreateImageDto[];
  dateTaken?: string;
  issueDescription?: string;
  issueType: string;
  fixTime?: number;
  issueStatus?: IssueStatus;
  location?: {
    coordinates: [number, number];
    type?: string;
  };
  assignee?: string;
  materialType: MaterialType;
  quantityUsed: Number;
  depth: Number;
  diameter: Number;
  _id?: string;
}
export interface commentType {
avatar?: string;
  comment?: string;
  reporter: string;
  userName: string;
  issueId: string;
  audioLink?: string;
  createdAt?: string;
}
export interface Notification {
  _id?:string;
  user: User;
  publicKey: string;
  privateKey: string;
  endpoint: string;
  expirationTime?: string;
  p256dh: string;
  auth: string;
}
export enum  NotificationsPermissionStatus{
  GRANTED='granted',
  DENIED='denied',
}
export enum UserPermissionType {
  CREATE_ISSUE = "create:issue",
  READ_ISSUE = "read:issue",
  UPDATE_ISSUE = "update:issue",
  DELETE_ISSUE = "delete:issue",
  UPDATE_STATUS_ISSUE = "updateStatus:issue",
  UPDATE_ASSIGNEE_ISSUE = "updateAssignee:issue",
  MANAGE_USERS="manageUsers:admin",
}
export enum UserRole {
  SUPER_ADMIN = "SuperAdmin",
  DEFAULT_ADMIN = "DefaultAdmin",
  ADMIN = "Admin",
  OPERATION = "Operation",
  USER = "User",
  GUEST = "Guest",
}
export interface AuthUserAccessProps {
  roleTypes: [];
  permissions: [];
}
export interface AuthUser{
      user_id: string;
      email: string;
      picture: string;
      name: string;
      blocked?:boolean;
}
export interface AuthRole{
  id:string;
  name:string;
  description:string;
  selected?:boolean;
}
export interface UserInvite{
  email:string;
  date:string;
  inviteAccepted:boolean;
}

export interface User{
  _id?:string;
  name: string;
  password: string;
  email: string;
  role:Role;
  picture?:string;
  blocked?:boolean;
}

export interface Role{
  _id?:string;
  name:string;
  description:string;
}

export interface Permission{
  _id?:string;
  name:string;
  description:string;
  selected?:boolean;
}

export interface RolePermission{
  _id?:string;
  role:Role;
  permission:Permission;
}

export enum fieldType {
  STRING = 'string',
  NUMBER = 'number',
}

export enum NotificationMessageTypes{
  ISSUE_CREATION_TO_ADMIN = "issue_create_admin",
  ISSUE_UPDATION_TO_REPORTER="issue_updation_reporter",
  ISSUE_UPDATION_TO_ASSIGNEE="issue_updation_assignee",
  ISSUE_UPDATION_TO_SUBSCRIBER="issue_updation_subscriber"
}
export interface IssueStatusInvites{
  _id?:string,
  email?:string,
  issueStatuses?:[{  name:string;
    description?:string;
    selected?:boolean;}]
}

export interface NotificationSettings {
  _id?:string;
  email: string;
  createdAt?: string;
  issueStatuses?:string[];
  issueIds?:string[];
}

export interface NotificationInformation{
  fromEmail:string,
  toEmail?:string,
  serverUrl?:string,
  issueId:string,
  issueStatus?:string,
  message?:string,
  title:string,
}

export interface NotificationDispatchInformation{
notificationRecipients:NotificationRecipient[],
issueId:string,
fromEmail:string,
title:string,
}

export enum RecipientTYPE{
  ADMIN = 'ADMIN',
  REPORTER = 'REPORTER',
  ASSIGNEE = 'ASSIGNEE',
  SUBSCRIBER = 'SUBSCRIBER',
}

export interface NotificationRecipient{
  message:string,
  type:RecipientTYPE,
  serverUrl:string,
}