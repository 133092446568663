import { jwtDecode } from 'jwt-decode';
import { type Permission, UserPermissionType } from '../../shared/shared.interface';

export const useIsAuthenticated = () => {
    const token = localStorage.getItem('authToken');

    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken?.exp < currentTime) {
                localStorage.removeItem('authToken');
                return false;
            }
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
            return false;
        }

    return !!token;
};

export const useAuthToken = () => {
    const token = localStorage.getItem('authToken');

    if (token)
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;

            if (decodedToken?.exp < currentTime) {
                localStorage.removeItem('authToken');
                return null;
            }
        } catch (error) {
            // Handle token decoding error
            localStorage.removeItem('authToken');
            return null;
        }

    return token;
};

export const useIsAdminUser = () => {
    let isAdminUser = false;
    const token = localStorage.getItem('authToken');

    if (token) {
        const decodedToken = jwtDecode(token);
        const permissions:Permission[] = decodedToken.permissions;
        if (permissions && permissions.length) {
            const adminUserPermission = permissions.filter((permission) => {
                return permission.name === UserPermissionType.MANAGE_USERS;
            });
            if (adminUserPermission.length)
                isAdminUser = true;
        }
    }

    return isAdminUser;
};
