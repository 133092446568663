import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoCalendar, LazySvgoCamera, LazySvgoCategory, LazySvgoComplexity, LazySvgoDelete, LazySvgoExpandDown, LazySvgoFileUpload, LazySvgoFilter, LazySvgoGlobe, LazySvgoGrid, LazySvgoHambergerMenu, LazySvgoHomeIcon, LazySvgoHome, LazySvgoIconBack, LazySvgoIconCancel, LazySvgoIconCross, LazySvgoIconEdit, LazySvgoIconExpandDown, LazySvgoIconExpandUp, LazySvgoIconMic, LazySvgoIconPlay, LazySvgoIconPlus, LazySvgoIconSend, LazySvgoIconStop, LazySvgoMoney, LazySvgoNotifications, LazySvgoPermissions, LazySvgoPhoto, LazySvgoPlusSmall, LazySvgoRoadPin, LazySvgoSetting, LazySvgoUserCircled, LazySvgoUserInvites, LazySvgoUsersGroup, LazySvgoUsersManagement, LazySvgoUsersRoles, LazySvgoUsers } from '#components'
const lazyGlobalComponents = [
  ["SvgoCalendar", LazySvgoCalendar],
["SvgoCamera", LazySvgoCamera],
["SvgoCategory", LazySvgoCategory],
["SvgoComplexity", LazySvgoComplexity],
["SvgoDelete", LazySvgoDelete],
["SvgoExpandDown", LazySvgoExpandDown],
["SvgoFileUpload", LazySvgoFileUpload],
["SvgoFilter", LazySvgoFilter],
["SvgoGlobe", LazySvgoGlobe],
["SvgoGrid", LazySvgoGrid],
["SvgoHambergerMenu", LazySvgoHambergerMenu],
["SvgoHomeIcon", LazySvgoHomeIcon],
["SvgoHome", LazySvgoHome],
["SvgoIconBack", LazySvgoIconBack],
["SvgoIconCancel", LazySvgoIconCancel],
["SvgoIconCross", LazySvgoIconCross],
["SvgoIconEdit", LazySvgoIconEdit],
["SvgoIconExpandDown", LazySvgoIconExpandDown],
["SvgoIconExpandUp", LazySvgoIconExpandUp],
["SvgoIconMic", LazySvgoIconMic],
["SvgoIconPlay", LazySvgoIconPlay],
["SvgoIconPlus", LazySvgoIconPlus],
["SvgoIconSend", LazySvgoIconSend],
["SvgoIconStop", LazySvgoIconStop],
["SvgoMoney", LazySvgoMoney],
["SvgoNotifications", LazySvgoNotifications],
["SvgoPermissions", LazySvgoPermissions],
["SvgoPhoto", LazySvgoPhoto],
["SvgoPlusSmall", LazySvgoPlusSmall],
["SvgoRoadPin", LazySvgoRoadPin],
["SvgoSetting", LazySvgoSetting],
["SvgoUserCircled", LazySvgoUserCircled],
["SvgoUserInvites", LazySvgoUserInvites],
["SvgoUsersGroup", LazySvgoUsersGroup],
["SvgoUsersManagement", LazySvgoUsersManagement],
["SvgoUsersRoles", LazySvgoUsersRoles],
["SvgoUsers", LazySvgoUsers],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
