import { default as permissionsCf7CbItGM8Meta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/permissions.vue?macro=true";
import { default as permissionsTJwf4c7a11Meta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/role/permissions.vue?macro=true";
import { default as rolesBbn2U8rnAPMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/roles.vue?macro=true";
import { default as userinvitesGlqba0WMaxMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/userinvites.vue?macro=true";
import { default as usersn2QR0DjaMaMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/users.vue?macro=true";
import { default as appsettingsjGZbq4gsVwMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/appsettings.vue?macro=true";
import { default as callbackdnyx5gTzNbMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/callback.vue?macro=true";
import { default as categories9D8cehW9pVMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/categories.vue?macro=true";
import { default as custom_45fields_91_91id_93_93ln0i7rMKSNMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/custom-fields[[id]].vue?macro=true";
import { default as indexwtuO5BWKdcMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/index.vue?macro=true";
import { default as issue_45form_91_91id_93_93mLtrWtn843Meta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/issue-form[[id]].vue?macro=true";
import { default as details_45_91id_93IDCYMESnn9Meta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/issue/details-[id].vue?macro=true";
import { default as issuesQcWXohjmgrMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/issues.vue?macro=true";
import { default as logini3FagRh4umMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/login.vue?macro=true";
import { default as notificationSettingsQHs74nddFNMeta } from "/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/notificationSettings.vue?macro=true";
export default [
  {
    name: permissionsCf7CbItGM8Meta?.name ?? "admin-permissions",
    path: permissionsCf7CbItGM8Meta?.path ?? "/admin/permissions",
    meta: permissionsCf7CbItGM8Meta || {},
    alias: permissionsCf7CbItGM8Meta?.alias || [],
    redirect: permissionsCf7CbItGM8Meta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/permissions.vue").then(m => m.default || m)
  },
  {
    name: permissionsTJwf4c7a11Meta?.name ?? "admin-role-permissions",
    path: permissionsTJwf4c7a11Meta?.path ?? "/admin/role/permissions",
    meta: permissionsTJwf4c7a11Meta || {},
    alias: permissionsTJwf4c7a11Meta?.alias || [],
    redirect: permissionsTJwf4c7a11Meta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/role/permissions.vue").then(m => m.default || m)
  },
  {
    name: rolesBbn2U8rnAPMeta?.name ?? "admin-roles",
    path: rolesBbn2U8rnAPMeta?.path ?? "/admin/roles",
    meta: rolesBbn2U8rnAPMeta || {},
    alias: rolesBbn2U8rnAPMeta?.alias || [],
    redirect: rolesBbn2U8rnAPMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/roles.vue").then(m => m.default || m)
  },
  {
    name: userinvitesGlqba0WMaxMeta?.name ?? "admin-userinvites",
    path: userinvitesGlqba0WMaxMeta?.path ?? "/admin/userinvites",
    meta: userinvitesGlqba0WMaxMeta || {},
    alias: userinvitesGlqba0WMaxMeta?.alias || [],
    redirect: userinvitesGlqba0WMaxMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/userinvites.vue").then(m => m.default || m)
  },
  {
    name: usersn2QR0DjaMaMeta?.name ?? "admin-users",
    path: usersn2QR0DjaMaMeta?.path ?? "/admin/users",
    meta: usersn2QR0DjaMaMeta || {},
    alias: usersn2QR0DjaMaMeta?.alias || [],
    redirect: usersn2QR0DjaMaMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: appsettingsjGZbq4gsVwMeta?.name ?? "appsettings",
    path: appsettingsjGZbq4gsVwMeta?.path ?? "/appsettings",
    meta: appsettingsjGZbq4gsVwMeta || {},
    alias: appsettingsjGZbq4gsVwMeta?.alias || [],
    redirect: appsettingsjGZbq4gsVwMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/appsettings.vue").then(m => m.default || m)
  },
  {
    name: callbackdnyx5gTzNbMeta?.name ?? "callback",
    path: callbackdnyx5gTzNbMeta?.path ?? "/callback",
    meta: callbackdnyx5gTzNbMeta || {},
    alias: callbackdnyx5gTzNbMeta?.alias || [],
    redirect: callbackdnyx5gTzNbMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/callback.vue").then(m => m.default || m)
  },
  {
    name: categories9D8cehW9pVMeta?.name ?? "categories",
    path: categories9D8cehW9pVMeta?.path ?? "/categories",
    meta: categories9D8cehW9pVMeta || {},
    alias: categories9D8cehW9pVMeta?.alias || [],
    redirect: categories9D8cehW9pVMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: custom_45fields_91_91id_93_93ln0i7rMKSNMeta?.name ?? "custom-fieldsid",
    path: custom_45fields_91_91id_93_93ln0i7rMKSNMeta?.path ?? "/custom-fields:id?",
    meta: custom_45fields_91_91id_93_93ln0i7rMKSNMeta || {},
    alias: custom_45fields_91_91id_93_93ln0i7rMKSNMeta?.alias || [],
    redirect: custom_45fields_91_91id_93_93ln0i7rMKSNMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/custom-fields[[id]].vue").then(m => m.default || m)
  },
  {
    name: indexwtuO5BWKdcMeta?.name ?? "index",
    path: indexwtuO5BWKdcMeta?.path ?? "/",
    meta: indexwtuO5BWKdcMeta || {},
    alias: indexwtuO5BWKdcMeta?.alias || [],
    redirect: indexwtuO5BWKdcMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: issue_45form_91_91id_93_93mLtrWtn843Meta?.name ?? "issue-formid",
    path: issue_45form_91_91id_93_93mLtrWtn843Meta?.path ?? "/issue-form:id?",
    meta: issue_45form_91_91id_93_93mLtrWtn843Meta || {},
    alias: issue_45form_91_91id_93_93mLtrWtn843Meta?.alias || [],
    redirect: issue_45form_91_91id_93_93mLtrWtn843Meta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/issue-form[[id]].vue").then(m => m.default || m)
  },
  {
    name: details_45_91id_93IDCYMESnn9Meta?.name ?? "issue-details-id",
    path: details_45_91id_93IDCYMESnn9Meta?.path ?? "/issue/details-:id()",
    meta: details_45_91id_93IDCYMESnn9Meta || {},
    alias: details_45_91id_93IDCYMESnn9Meta?.alias || [],
    redirect: details_45_91id_93IDCYMESnn9Meta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/issue/details-[id].vue").then(m => m.default || m)
  },
  {
    name: issuesQcWXohjmgrMeta?.name ?? "issues",
    path: issuesQcWXohjmgrMeta?.path ?? "/issues",
    meta: issuesQcWXohjmgrMeta || {},
    alias: issuesQcWXohjmgrMeta?.alias || [],
    redirect: issuesQcWXohjmgrMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/issues.vue").then(m => m.default || m)
  },
  {
    name: logini3FagRh4umMeta?.name ?? "login",
    path: logini3FagRh4umMeta?.path ?? "/login",
    meta: logini3FagRh4umMeta || {},
    alias: logini3FagRh4umMeta?.alias || [],
    redirect: logini3FagRh4umMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: notificationSettingsQHs74nddFNMeta?.name ?? "notificationSettings",
    path: notificationSettingsQHs74nddFNMeta?.path ?? "/notificationSettings",
    meta: notificationSettingsQHs74nddFNMeta || {},
    alias: notificationSettingsQHs74nddFNMeta?.alias || [],
    redirect: notificationSettingsQHs74nddFNMeta?.redirect,
    component: () => import("/home/musman1/workspace/IssuesNetshore/Fixsirat-Admin/fixsirat-admin-frontend/pages/notificationSettings.vue").then(m => m.default || m)
  }
]