<template>
    <div class="spinner-container flex items-center justify-center">
        <div class="spinner-animation h-20 w-20 rounded-full bg-brand-400" />
    </div>
</template>

<style scoped>
.spinner-container {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

.spinner-animation {
-webkit-animation: sk-scaleout 1s infinite ease-in-out;
animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
0% {
  -webkit-transform: scale(0);
}
100% {
  -webkit-transform: scale(1);
  opacity: 0;
}
}

@keyframes sk-scaleout {
0% {
  -webkit-transform: scale(0);
  transform: scale(0);
}
100% {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 0;
}
}
</style>
